import Button from "@material-ui/core/Button";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNotify } from "react-admin";
import DownloadIcon from "@material-ui/icons/GetApp";
import Config from "../Config";

const getHeaders = () =>
  localStorage.getItem("token")
    ? {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      }
    : {};

const getFilters = (location) => {
  const urlParams = new URLSearchParams(location.search);
  const filterParam = urlParams.get("filter");

  // Check if the filterParam exists before decoding
  if (filterParam) {
    const decodedFilterParam = decodeURIComponent(filterParam);
    return JSON.parse(decodedFilterParam);
  }

  return {};
};

const objectToQueryString = (obj) => {
  return Object.keys(obj)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join("&");
};

const ExportButton = (props) => {
  const [disabledButton, setDisabledButton] = useState(false);
  const notify = useNotify();
  const location = useLocation();

  const filters = objectToQueryString(getFilters(location));

  if (!props.resource) {
    throw new Error("Resource is not defined");
  }

  const exportRequest = () => {
    const endpoint = `${Config.REACT_APP_API_ENTRYPOINT}/export/${props.resource}?${filters}`;
    setDisabledButton(true);

    fetch(endpoint, {
      method: "GET",
      headers: getHeaders(),
      priority: "high",
    })
      .then((response) => response.json())
      .then((data) => {
        notify(data, "info");

        setTimeout(() => {
          setDisabledButton(false);
        }, 10000);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <Button
      onClick={exportRequest}
      disabled={disabledButton}
      startIcon={<DownloadIcon />}
      color={"primary"}
      size={"small"}
    >
      Export
    </Button>
  );
};

export default ExportButton;
