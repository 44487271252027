import { FieldGuesser, ListGuesser } from "@api-platform/admin";
import React from "react";
import jsonExport from "jsonexport/dist";
import { ReferenceField, downloadCSV } from "react-admin";
import AvatarField from "../util_components/avatarField";
import CustomPagination from "../util_components/customPagination";
import { FilterGuesserExtend } from "../util_components/FilterGuesserExtend";
import PriceChip from "../util_components/priceChip";
import StateChip from "../util_components/stateChip";
import ToolSearchInput from "../util_components/toolSearchInput";
import ListActionsExtend from "../util_components/ListActionsExtend";
import ExportButton from "../util_components/exportButton";

const exporter = async (records, fetchRelatedRecords) => {
  const tools = await fetchRelatedRecords(records, "tool", "tools");
  const mediaObjects = await fetchRelatedRecords(
    records,
    "mediaObject",
    "media_objects"
  );
  const owners = await fetchRelatedRecords(records, "owner", "users");
  const data = records.map((record) => {
    delete record["@id"];
    delete record["@type"];
    delete record["id"];
    return {
      ...record,
      tool: record.tool && tools[record.tool] ? tools[record.tool].name : "",
      mediaObject:
        record.mediaObject && mediaObjects[record.mediaObject]
          ? mediaObjects[record.mediaObject].uuid
          : "",
      owner:
        record.owner && owners[record.owner] ? owners[record.owner].email : "",
      date: record.date ? new Date(record.date).toLocaleString() : "",
      offlineUpdatedAt: record.offlineUpdatedAt
        ? new Date(record.offlineUpdatedAt).toLocaleString()
        : "",
      createdAt: record.createdAt
        ? new Date(record.createdAt).toLocaleString()
        : "",
      updatedAt: record.updatedAt
        ? new Date(record.updatedAt).toLocaleString()
        : "",
    };
  });

  jsonExport(data, { rowDelimiter: ";" }, (err, csv) => {
    downloadCSV(csv, "events");
  });
};

const EventList = (props) => {
  return (
    <ListGuesser
      actions={
        <ListActionsExtend
          actionsToAdd={[
            {
              name: "exportButton",
              component: <ExportButton resource="events" />,
            },
          ]}
        />
      }
      exporter={false}
      perPage={50}
      pagination={<CustomPagination {...props} />}
      filters={
        <FilterGuesserExtend
          filterstoadd={[
            {
              name: "tool",
              component: (
                <ToolSearchInput
                  source={"tool"}
                  key="tool"
                  helperText=""
                  filterToQuery={(searchText) => ({ serial: searchText })}
                ></ToolSearchInput>
              ),
            },
          ]}
          {...props}
        />
      }
      {...props}
    >
      <FieldGuesser source={"tool"} sortBy="tool.name" label="Tool name" />
      <FieldGuesser source={"type"} label="Event type" />
      <FieldGuesser source={"name"} label="Event name" />
      <FieldGuesser source={"date"} />
      <PriceChip source={"cost"} sortable={false} />
      <StateChip source={"state"} />
      <ReferenceField
        source="mediaObject"
        reference="media_objects"
        sortBy="mediaObject.uuid"
      >
        <AvatarField />
      </ReferenceField>
    </ListGuesser>
  );
};

export default EventList;
