import { FieldGuesser, ShowGuesser } from "@api-platform/admin";
import React from "react";
import ToolSetupTypeField from "../util_components/toolSetupTypeField";

const ToolSetupShow = (props) => {
  return (
    <ShowGuesser {...props}>
      <FieldGuesser source={"uuid"} addLabel={true} />
      <FieldGuesser source={"owner"} addLabel={true} />
      <ToolSetupTypeField source={"type"} label="Setup type" />
      <FieldGuesser source={"name"} addLabel={true} label="Setup name" />
      <FieldGuesser source={"setup"} addLabel={true} />
    </ShowGuesser>
  );
};

export default ToolSetupShow;
