import { FieldGuesser, ShowGuesser } from "@api-platform/admin";
import React from "react";
import { DateField } from "react-admin";

const LogsShow = (props) => (
  <ShowGuesser {...props}>
    <FieldGuesser source={"bluetoothId"} addLabel={true} label="Tool uuid" />
    <FieldGuesser source={"owner"} addLabel={true} />
    <DateField source="createdAt" showTime />
  </ShowGuesser>
);

export default LogsShow;
