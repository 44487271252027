import { sanitizeListRestProps, useListContext } from "ra-core";
import { CreateButton, TopToolbar, FilterButton } from "ra-ui-materialui";
import React, { cloneElement } from "react";
import ExportButton from "./exportButton";

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, actionsToAdd, ...rest } =
    props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    showFilter,
    total,
  } = useListContext({ filters: false });
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {hasCreate &&
        !actionsToAdd?.find(
          (actionToAdd) => actionToAdd.name === "hasCreate"
        ) && <CreateButton basePath={basePath} />}
      {!!exporter && (
        <ExportButton resource={resource} disabled={total === 0} />
      )}
      {actionsToAdd && actionsToAdd.map((actionToAdd) => actionToAdd.component)}
    </TopToolbar>
  );
};

export default ListActions;
