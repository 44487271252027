import React from "react";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";

const useStyles = makeStyles({
  chip: {
    color: "white",
    backgroundColor: "#52aaf9",
    minWidth: 90,
  },
  icon: {
    color: "white",
  },
});

const PriceChip = ({ record }) => {
  const classes = useStyles();
  const cost = record && record.cost;

  function getReadablePrice(amount) {
    if (amount === null) return "—";
    if (typeof amount !== "string") amount = String(amount);
    return amount.length === 1
      ? `0,0${amount}`
      : amount.length === 2
      ? `0,${amount}`
      : `${amount.substring(0, amount.length - 2)},${amount.substring(
          amount.length - 2
        )}`;
  }

  return (
    cost &&
    cost.currency && (
      <Chip
        icon={<AccountBalanceWalletOutlinedIcon className={classes.icon} />}
        label={`${cost && getReadablePrice(cost.amount)} ${
          cost && cost.currency
        }`}
        className={classes.chip}
      />
    )
  );
};

export default PriceChip;
