import { FieldGuesser, ListGuesser } from "@api-platform/admin";
import { Typography } from "@material-ui/core";
import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";
import React from "react";
import CustomPagination from "../util_components/customPagination";
import { FilterGuesserExtend } from "../util_components/FilterGuesserExtend";
import ToolSetupTypeField from "../util_components/toolSetupTypeField";
import UserSearchInput from "../util_components/userSearchInput";

const exporter = async (records, fetchRelatedRecords) => {
  const owners = await fetchRelatedRecords(records, "owner", "users");
  const data = records.map((record) => {
    delete record[ "@id" ];
    delete record[ "@type" ];
    delete record[ "id" ];
    return {
      ...record,
      owner:
        record.owner && owners[ record.owner ] ? owners[ record.owner ].email : "",
    };
  });

  jsonExport(data, { rowDelimiter: ";" }, (err, csv) => {
    downloadCSV(csv, "toolSetups");
  });
};

const ToolSetupValueLabel = ({ record, source }) => {
  const setup = record && record[ source ];
  return setup ? (
    <Typography variant="subtitle2" gutterBottom>
      { setup.sequence && `${ setup[ "sequence" ] } x ` }
      { setup.power && `${ setup[ "power" ] }% ` }
      { setup.torque &&
        `${ setup[ "torque" ][ "value" ] } ${ setup[ "torque" ][ "unit" ] } ` }
      { setup.angle && `${ setup[ "angle" ] }° ` }
      { setup.time && `${ setup[ "time" ] } secs ` }
    </Typography>
  ) : null;
};

const ToolSetupList = (props) => (
  <ListGuesser
    exporter={ exporter }
    filterDefaultValues={ { archived: false } }
    perPage={ 50 }
    pagination={ <CustomPagination { ...props } /> }
    filters={
      <FilterGuesserExtend
        filterstoadd={ [
          {
            name: "owner",
            component: (
              <UserSearchInput
                source={ "owner" }
                key="owner"
                helperText=""
                filterToQuery={ searchText => ({ email: searchText }) }
              ></UserSearchInput>
            ),
          },
        ] }
        { ...props }
      />
    }
    { ...props }
  >
    <FieldGuesser source={ "owner" } sortBy="owner.email" />
    <ToolSetupTypeField source={ "type" } label="Setup type" />
    <FieldGuesser source={ "name" } label="Setup name" />
    <ToolSetupValueLabel source={ "setup" } sortable={ false } />
  </ListGuesser>
);

export default ToolSetupList;
