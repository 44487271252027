import { FieldGuesser, ListGuesser } from "@api-platform/admin";
import React from "react";
import jsonExport from "jsonexport/dist";
import { DateField, downloadCSV } from "react-admin";
import CustomPagination from "../util_components/customPagination";
import { FilterGuesserExtend } from "../util_components/FilterGuesserExtend";
import UserSearchInput from "../util_components/userSearchInput";
import ExportButton from "../util_components/exportButton";
import ListActionsExtend from "../util_components/ListActionsExtend";

const LogsList = (props) => (
  <ListGuesser
    actions={
      <ListActionsExtend
        actionsToAdd={[
          {
            name: "exportButton",
            component: <ExportButton resource="logs" />,
          },
        ]}
      />
    }
    exporter={false}
    perPage={50}
    pagination={<CustomPagination {...props} />}
    filters={
      <FilterGuesserExtend
        filterstoadd={[
          {
            name: "owner",
            component: (
              <UserSearchInput
                source={"owner"}
                key="owner"
                helperText=""
                filterToQuery={(searchText) => ({ email: searchText })}
              ></UserSearchInput>
            ),
          },
        ]}
        {...props}
      />
    }
    {...props}
  >
    <FieldGuesser source={"bluetoothId"} label="Tool uuid" />
    <FieldGuesser source={"owner"} sortBy="owner.email" />
    <DateField source="createdAt" showTime />
  </ListGuesser>
);

export default LogsList;
