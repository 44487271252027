import { FieldGuesser, ListGuesser } from "@api-platform/admin";
import jsonExport from "jsonexport/dist";
import React from "react";
import { DateField, downloadCSV } from "react-admin";
import BatteryField from "../util_components/batteryField";
import CustomPagination from "../util_components/customPagination";
import EventCountField from "../util_components/eventCountField";
import ExportButton from '../util_components/exportButton';
import { FilterGuesserExtend } from "../util_components/FilterGuesserExtend";
import ListActionsExtend from "../util_components/ListActionsExtend";
import SetupCountField from "../util_components/setupCountField";
import StateChip from "../util_components/stateChip";
import UserSearchInput from "../util_components/userSearchInput";

const ToolList = (props) => (
  <ListGuesser
    filterDefaultValues={ { archived: false } }
    actions={
      <ListActionsExtend actionsToAdd={[
        {
          name: 'exportButton',
          component: (
            <ExportButton resource="tools" />
          ),
        },
      ]} />}
    exporter={false}
    perPage={ 50 }
    pagination={ <CustomPagination { ...props } /> }
    filters={
      <FilterGuesserExtend
        filterstoadd={ [
          {
            name: "owner",
            component: (
              <UserSearchInput
                source={ "owner" }
                key="owner"
                helperText=""
                filterToQuery={ searchText => ({ email: searchText }) }
              ></UserSearchInput>
            ),
          },
        ] }
        { ...props }
      />
    }
    { ...props }
  >
    <FieldGuesser source={ "model" } sortBy="model.model" />
    <FieldGuesser source={ "serial" } label="Serial number" />
    <StateChip source={ "state" } />
    <FieldGuesser source={ "name" } label="Tool name" />
    <EventCountField source={ "events" } sortable={ false } />
    <SetupCountField source={ "setups" } sortable={ false } />
    <BatteryField source={ "battery" } />
    <FieldGuesser source={ "owner" } sortBy="owner.email" />
    <DateField source="createdAt" />
    <DateField source="updatedAt" />
  </ListGuesser>
);

export default ToolList;
