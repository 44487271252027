import React from "react";
import { ShowGuesser, FieldGuesser } from "@api-platform/admin";
import PriceChip from "../util_components/priceChip";

const EventShow = (props) => (
  <ShowGuesser {...props}>
    <FieldGuesser source={"tool"} addLabel={true} label="Tool name" />
    <FieldGuesser source={"type"} addLabel={true} label="Event type" />
    <FieldGuesser source={"name"} addLabel={true} label="Event name" />
    <FieldGuesser source={"description"} addLabel={true} />
    <FieldGuesser source={"date"} addLabel={true} />
    <FieldGuesser source={"attributes"} addLabel={true} />
    <PriceChip source={"cost"} addLabel={true} />
    <FieldGuesser source={"uuid"} addLabel={true} />
    <FieldGuesser source={"createdAt"} addLabel={true} />
    <FieldGuesser source={"updatedAt"} addLabel={true} />
    <FieldGuesser source={"state"} addLabel={true} />
    <FieldGuesser source={"mediaObject"} addLabel={true} />
  </ShowGuesser>
);

export default EventShow;
