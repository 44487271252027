import React from "react";
import { ShowGuesser, FieldGuesser } from "@api-platform/admin";

const MediaObjectShow = (props) => (
  <ShowGuesser {...props}>
    <FieldGuesser source={"file"} addLabel={true} />
    <FieldGuesser source={"name"} addLabel={true} label="File name" />
    <FieldGuesser source={"mimeType"} addLabel={true} label="File type" />
    <FieldGuesser source={"uuid"} addLabel={true} label="File uuid" />
    <FieldGuesser source={"id"} addLabel={true} />
    <FieldGuesser source={"createdAt"} addLabel={true} />
    <FieldGuesser source={"updatedAt"} addLabel={true} />
  </ShowGuesser>
);

export default MediaObjectShow;
